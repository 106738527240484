import React, { useEffect } from 'react'
import service_img from '../images/services.jpg'
import web_Development from '../images/domain.png'
import Ai_development from '../images/AI.png'
import ml from '../images/machine-learning.png'
import wordpress from '../images/wordpress.png'
import dataAnalysis from '../images/dataAnalysis.png'
import dataVisualization from '../images/dataVisualization.png'
import dataEnginerring from '../images/data-engineer.png'
import pipeline from '../images/etl.png'
import AIConsultant from '../images/robot-assistant.png'
import webscraping from '../images/webscraping.png'
import automation from '../images/automation.png'
import Fiverr_Scraping from '../images/FScrap.jpg'
import Fiverr_Automation_Bot from '../images/Fbot.jpg'
import Fiverr_Automation from '../images/Fautomation.jpg'
import Fiverr_Cleanng_Data from '../images/Fcleaning.png'
import Fiverr_Databricks from '../images/Fdatabricks.png'
import Fiverr_Data_Analytics from '../images/FdataAnalytics.png'
import Fiverr_WEB_AI from '../images/FwebAI.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'

function Servicespage() {

  document.title = 'Services - WorknConnect Innovators'

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])

    const fiverr_Services = [
        {
            card_Image: Fiverr_Scraping,
            card_title: 'Web Scraping',
            card_description: 'I will do web scraping using python',
            card_Link: 'https://www.fiverr.com/s/jjqPGGa'
        },
        {
            card_Image: Fiverr_Automation_Bot,
            card_title: 'Automation Bot',
            card_description: 'I will do automation and create bots using python selenium',
            card_Link: 'https://www.fiverr.com/s/WEY68jd'
        },
        {
            card_Image: Fiverr_Automation,
            card_title: 'App Automation',
            card_description: 'I will do android app automation and app data scraping',
            card_Link: 'https://www.fiverr.com/s/wkmdKLA'
        },
        {
            card_Image: Fiverr_Cleanng_Data,
            card_title: 'Data Cleaning',
            card_description: 'I will do cleaning and formatting data using python',
            card_Link: 'https://www.fiverr.com/s/1qkzQxk'
        },
        {
            card_Image: Fiverr_Databricks,
            card_title: 'ETL Pipelines',
            card_description: 'I will create etl or elt pipelines in azure datafactory and databricks',
            card_Link: 'https://www.fiverr.com/s/qDylGgZ'
        },
        {
            card_Image: Fiverr_Data_Analytics,
            card_title: 'Data Analytics',
            card_description: 'I will perform data analytics to grow your business',
            card_Link: 'https://www.fiverr.com/s/Zm965Q8'
        },
        {
            card_Image: Fiverr_WEB_AI,
            card_title: 'AI Integrations',
            card_description: 'I will create web application with your ai model integrated',
            card_Link: 'https://www.fiverr.com/s/m53XRbx'
        },
    ]

    const Web_Services = [
        {
            card_Image: web_Development,
            card_title: 'Web Development',
            card_description: 'Building responsive, modern websites tailored to your business needs.',
            card_Link: ''
        },
        {
            card_Image: wordpress,
            card_title: 'WordPress',
            card_description: 'Custom WordPress development for versatile and user-friendly websites.',
            card_Link: ''
        },
    ]

    const DataScience_Services = [
        {
            card_Image: Ai_development,
            card_title: 'AI Development',
            card_description: 'Innovative AI solutions to automate and enhance your business.',
            card_Link: ''
        },
        {
            card_Image: ml,
            card_title: 'ML/DL Development',
            card_description: 'Advanced machine learning and deep learning services for data-driven insights.',
            card_Link: ''
        },
        {
            card_Image: dataAnalysis,
            card_title: 'Data Analysis',
            card_description: 'Turning data into actionable insights with expert analysis.',
            card_Link: ''
        },
        {
            card_Image: dataVisualization,
            card_title: 'Data Visualization',
            card_description: 'Visualizing complex data to make informed decisions.',
            card_Link: ''
        },
        {
            card_Image: AIConsultant,
            card_title: 'AI Consultancy',
            card_description: 'Guiding your AI strategy for impactful business transformation.',
            card_Link: ''
        },
    ]

    const dataEnginerring_services = [


        {
            card_Image: dataEnginerring,
            card_title: 'Data Engineering',
            card_description: 'Building robust data pipelines and architectures for seamless data flow.',
            card_Link: ''
        },
        {
            card_Image: pipeline,
            card_title: 'DataBricks / Pipeline',
            card_description: 'Streamlining your data workflows with Databricks and custom pipelines.',
            card_Link: ''
        },

        {
            card_Image: automation,
            card_title: 'Web/App Automation',
            card_description: 'Automating web and app processes to enhance efficiency and accuracy.',
            card_Link: ''
        },
        {
            card_Image: webscraping,
            card_title: 'Web Scraping',
            card_description: 'Extracting valuable data from websites with advanced scraping techniques.',
            card_Link: ''
        },

    ];

    const courses = [
        {
            course_title: 'Python',
            course_description: 'Learn Python programming from basics to advanced concepts, including data structures, algorithms, and more.',
        },
        {
            course_title: 'Web Development',
            course_description: 'Master the art of building responsive, dynamic websites using HTML, CSS, JavaScript, and modern frameworks.',
        },
        {
            course_title: 'AI Development',
            course_description: 'Dive into Artificial Intelligence with hands-on projects in machine learning, neural networks, and deep learning.',
        },
        {
            course_title: 'Django Development',
            course_description: 'Build robust web applications with Django, mastering backend development, RESTful APIs, and database management.',
        },
        {
            course_title: 'Web Scraping',
            course_description: 'Extract valuable data from websites using powerful web scraping tools and techniques with Python.',
        },
        {
            course_title: 'Web/APP Automation',
            course_description: 'Automate web and mobile applications with advanced techniques using tools like Selenium, Puppeteer, and Appium.',
        },
    ];



    return (

        <div className='overflow-x-hidden' >
            <div className="h-[40vh] my-10 w-full flex justify-center items-center relative">
                <img src={service_img} alt="" className="absolute top-0 left-0 w-full h-full object-cover" />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 flex flex-col justify-center items-center">
                    <h1
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className="lg:text-7xl text-5xl font-bold text-white z-10 relative after:absolute after:content-[''] after:block after:w-full after:h-1 after:bg-[#367ba0] after:bottom-[-10px] after:left-0"
                    >
                        Our Services
                    </h1>

                </div>
            </div>


            <div className=' bg-gray-100 flex flex-col gap-y-10 lg:p-20 p-8 my-10'>
                <div className='flex flex-col justify-center w-full items-center gap-y-4 ' >
                    <h1 className="text-[#367ba0] font-bold lg:text-4xl text-2xl  ">Our Top Notch Services</h1>
                    <p className='lg:w-1/2 text-center text-gray-700' >
                        We bring your business online with tailored solutions that streamline your operations
                        and enhance your digital presence.
                    </p>
                </div>
                <div>
                    <div className=' bg-[#367ba0] w-full text-center p-4 font-bold text-2xl rounded-lg text-white ' >Our Fiverr Services</div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-x-4 lg:gap-y-10 gap-y-4 my-6 ' >
                        {
                            fiverr_Services.map((card, index) => (

                                <div key={index} className="group place-self-center w-full relative overflow-hidden transform hover:scale-105 transition-transform duration-500">
                                    <img src={card.card_Image} alt='' className='h-40 mx-auto transition-opacity duration-500 ease-in-out group-hover:opacity-0' />
                                    <h2 className='mt-4 text-black text-center text-xl font-semibold transition-opacity duration-500 ease-in-out group-hover:opacity-0'>{card.card_title}</h2>

                                    <div className="absolute inset-0 flex flex-col gap-y-2 my-auto justify-center items-center transform translate-y-full group-hover:translate-y-0 transition-transform duration-700 ease-in-out delay-300">
                                        <h2 className="text-black text-xl font-semibold opacity-0 transition-opacity duration-500 ease-in-out delay-300 group-hover:opacity-100">{card.card_title}</h2>
                                        <p className='mb-2 w-3/4 text-sm text-center text-gray-800 opacity-0 transition-opacity duration-500 ease-in-out delay-500 group-hover:opacity-100'>
                                            {card.card_description}
                                        </p>
                                        <button className='mb-4 px-6 py-2 border-2 border-gray-900 text-black font-semibold rounded-full shadow-lg opacity-0 transition-opacity duration-500 ease-in-out delay-700 group-hover:opacity-100 hover:bg-white hover:text-[#367ba0]'>
                                            <a href={card.card_Link} >Hire Now</a>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <div className=' bg-[#367ba0] w-full text-center p-4 font-bold text-2xl rounded-lg text-white ' >Website Development</div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 my-6 ' >
                        {
                            Web_Services.map((card, index) => (

                                <div key={index} className="group place-self-center w-full relative px-6 py-10 bg-[#367ba0] shadow-xl rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-500">
                                    <img src={card.card_Image} alt='' className='h-20 mx-auto transition-opacity duration-500 ease-in-out group-hover:opacity-0' />
                                    <h2 className='mt-4 text-white text-center text-xl font-semibold transition-opacity duration-500 ease-in-out group-hover:opacity-0'>{card.card_title}</h2>

                                    <div className="absolute inset-0 flex flex-col gap-y-2 my-auto justify-center items-center transform translate-y-full group-hover:translate-y-0 transition-transform duration-700 ease-in-out delay-300">
                                        <h2 className="text-white text-xl font-semibold opacity-0 transition-opacity duration-500 ease-in-out delay-300 group-hover:opacity-100">{card.card_title}</h2>
                                        <p className='mb-2 w-3/4 text-sm text-center text-gray-200 opacity-0 transition-opacity duration-500 ease-in-out delay-500 group-hover:opacity-100'>
                                            {card.card_description}
                                        </p>
                                        <button className='mb-4 px-6 py-2 border-2 border-gray-400 text-white font-semibold rounded-full shadow-lg opacity-0 transition-opacity duration-500 ease-in-out delay-700 group-hover:opacity-100 hover:bg-white hover:text-[#367ba0]'>
                                            <a href={card.card_Link} >Learn More</a>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <div className='bg-[#367ba0] w-full text-center p-4 font-bold text-2xl rounded-lg text-white ' >Data Science</div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 my-6 ' >
                        {
                            DataScience_Services.map((card, index) => (

                                <div key={index} className="group place-self-center w-full relative px-6 py-10 bg-[#367ba0] shadow-xl rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-500">
                                    <img src={card.card_Image} alt='' className='h-20 mx-auto transition-opacity duration-500 ease-in-out group-hover:opacity-0' />
                                    <h2 className='mt-4 text-white text-center text-xl font-semibold transition-opacity duration-500 ease-in-out group-hover:opacity-0'>{card.card_title}</h2>

                                    <div className="absolute inset-0 flex flex-col gap-y-2 my-auto justify-center items-center transform translate-y-full group-hover:translate-y-0 transition-transform duration-700 ease-in-out delay-300">
                                        <h2 className="text-white text-xl font-semibold opacity-0 transition-opacity duration-500 ease-in-out delay-300 group-hover:opacity-100">{card.card_title}</h2>
                                        <p className='mb-2 w-3/4 text-sm text-center text-gray-200 opacity-0 transition-opacity duration-500 ease-in-out delay-500 group-hover:opacity-100'>
                                            {card.card_description}
                                        </p>
                                        <button className='mb-4 px-6 py-2 border-2 border-gray-400 text-white font-semibold rounded-full shadow-lg opacity-0 transition-opacity duration-500 ease-in-out delay-700 group-hover:opacity-100 hover:bg-white hover:text-[#367ba0]'>
                                            <a href={card.card_Link} >Learn More</a>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>

                <div>
                    <div className='bg-[#367ba0] w-full text-center p-4 font-bold text-2xl rounded-lg text-white ' >Data Engineering</div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 my-6 ' >
                        {
                            dataEnginerring_services.map((card, index) => (

                                <div key={index} className="group place-self-center w-full relative px-6 py-10 bg-[#367ba0] shadow-xl rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-500">
                                    <img src={card.card_Image} alt='' className='h-20 mx-auto transition-opacity duration-500 ease-in-out group-hover:opacity-0' />
                                    <h2 className='mt-4 text-white text-center text-xl font-semibold transition-opacity duration-500 ease-in-out group-hover:opacity-0'>{card.card_title}</h2>

                                    <div className="absolute inset-0 flex flex-col gap-y-2 my-auto justify-center items-center transform translate-y-full group-hover:translate-y-0 transition-transform duration-700 ease-in-out delay-300">
                                        <h2 className="text-white text-xl font-semibold opacity-0 transition-opacity duration-500 ease-in-out delay-300 group-hover:opacity-100">{card.card_title}</h2>
                                        <p className='mb-2 w-3/4 text-sm text-center text-gray-200 opacity-0 transition-opacity duration-500 ease-in-out delay-500 group-hover:opacity-100'>
                                            {card.card_description}
                                        </p>
                                        <button className='mb-4 px-6 py-2 border-2 border-gray-400 text-white font-semibold rounded-full shadow-lg opacity-0 transition-opacity duration-500 ease-in-out delay-700 group-hover:opacity-100 hover:bg-white hover:text-[#367ba0]'>
                                            <a href={card.card_Link} >Learn More</a>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>

            </div>

            <div className=' flex flex-col justify-center items-center text-white bg-gradient-to-b from-[#367ba0] to-gray-600 lg:mx-20 mx-4 rounded-lg my-20 gap-y-10 lg:px-20 lg:py-20 px-8 py-8 ' >
                <h1 className='font-bold lg:text-4xl text-2xl text-center ' >Accelerate Your Career with Cutting-Edge Courses</h1>
                <p className=' text-center lg:text-base text-sm ' >Unlock your potential with our expertly crafted courses, tailored to equip you with the skills that industry leaders demand. Dive into practical, hands-on training that empowers you to stay ahead in the rapidly evolving tech landscape and achieve your professional ambitions</p>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4' >
                    {
                        courses.map((course, index) => (

                            <div key={index} className="w-full place-self-center px-6 py-10 bg-white bg-opacity-70 backdrop-blur-sm shadow-xl rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-500 flex flex-col gap-y-4 ">
                                <h2 className='mt-4 text-black text-center text-xl font-bold'>{course.course_title}</h2>

                                <p className='mb-2 text-sm text-center text-gray-800'>
                                    {course.course_description}
                                </p>

                            </div>
                        ))
                    }
                </div>
                <div>
                    <a href='https://forms.gle/PYeYDBjEh91Z4u7c7' target='blank' className='text-xl border border-gray-400 w-fit px-12 py-2 rounded-xl shadow-md hover:text-[#367ba0] hover:bg-white hover:shadow-2xl font-bold hover:transition-all hover:duration-500 ' >Enroll Now</a>
                </div>
            </div>

        </div>
    )
}

export default Servicespage;