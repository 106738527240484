import React, { useEffect } from 'react'
import about_img from '../images/product_bg.jpg'
import logo1 from '../images/website (1).png'
import logo2 from '../images/content-writing.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
import PlatformImage from '../images/wnc1.png'
import { Link } from 'react-router-dom'

function Products() {

    document.title = 'Products - WorknConnect Innovators'

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])


    return (
        <div>
            <div className="h-[40vh] my-10 w-full flex justify-center items-center relative">
                <img src={about_img} alt="" className="  absolute top-0 left-0 w-full h-full object-cover object-center" />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 flex flex-col justify-center items-center">
                    <h1
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        data-aos-delay="200"
                        className="lg:text-7xl text-5xl font-bold text-white z-10 relative after:absolute after:content-[''] after:block after:w-full after:h-1 after:bg-[#367ba0] after:bottom-[-10px] after:left-0"
                    >
                        Our Products
                    </h1>
                </div>
            </div>

            <div className=' bg-gray-100 flex flex-col gap-y-10 lg:p-20 p-8 my-10'>
                <div className='flex flex-col justify-center w-full items-center gap-y-4 ' >
                    <h1 className="text-[#367ba0] font-bold lg:text-4xl text-2xl  ">Our Top Products</h1>
                    <p className='lg:w-2/3 text-center text-gray-700' >
                        Discover a diverse range of innovative products designed to cater to the ever-evolving demands of modern industries and individuals. Our offerings blend advanced technology with intuitive design, ensuring seamless functionality and exceptional user experience. Whether it’s streamlining workflows, enhancing productivity, or driving growth, our products are engineered with precision and a commitment to excellence. Explore solutions that empower you to achieve your goals with efficiency, reliability, and style.
                    </p>
                </div>


                <ProductCard
                    ImageHref={PlatformImage}
                    title="WorknConnect"
                    button="View Details"
                    buttonHref="#"
                />
            </div>
        </div>
    )
}

export default Products

const ProductCard = ({
    ImageHref,
    title,
    button,
    buttonHref,
}) => {
    return (
        <>
            <div
                className="w-full md:w-1/2 xl:w-1/3 block "
            >
                <div className="relative mb-12">
                    <div className="overflow-hidden rounded-[10px] bg-gray-400 p-2">
                        <img src={ImageHref} alt="portfolio" className="w-full" />
                    </div>
                    <div className="relative z-10 mx-7 -mt-20 rounded-lg bg-[#367ba0] dark:bg-dark-2 py-[34px] px-3 text-center shadow-portfolio dark:shadow-box-dark">
                        <h3 className="text-dark dark:text-white mb-5 lg:text-xl font-bold">{title}</h3>
                        <button
                            className=" group text-white dark:text-dark-6 hover:border-primary hover:bg-primary inline-block rounded-md border border-stroke dark:border-dark-3 py-[10px] px-7 text-sm font-medium transition hover:text-black hover:bg-white group-hover:transition-all group-hover:duration-500 "
                        >
                            <span className='group-hover:hidden group-hover:transition-all group-hover:duration-500' >{button}</span>
                            <div className='hidden group-hover:block group-hover:transition-all group-hover:duration-500'  >
                                <div className='flex gap-x-2' >
                                    <a href='https://worknconnect.com/' target='blank' >
                                        <img src={logo1}
                                            alt='website'
                                            className='w-6'
                                            title='visit website '
                                            data-tooltip-target="tooltip-default"
                                        />
                                    </a>
                                    <Link to={'/Blogs'} >
                                        <img
                                            src={logo2} alt='blog'
                                            className='w-6'
                                            title='view blog '
                                            data-tooltip-target="tooltip-default"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};